import Nav from './components/Nav';
import HomeTitle from './components/HomeTitle';


const App = () => {
  return (
    <div className="flex flex-col h-screen bg-custom bg-cover overflow-auto" style={{ backgroundImage: "url('img/noise.png')" }}>
      <Nav />
      <HomeTitle />
      <div className='text-white px-4 container mx-auto flex-col flex justify-end relative mb-4 mt-[100px]'>
        <h3 className="font-thin text-ivy-grey-100 font-poppins mb-4 text-sm bottom-0">
          Ivy grows even in winter
        </h3>
      </div>
    </div>
  )
}

export default App;
