import React, { useState } from "react";
import { Link } from "react-router-dom";

const Nav = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <nav className="text-white py-4 flex justify-between sm:container sm:mx-auto px-4">
                <div className="flex items-center">
                    <Link to="/">
                        <img src="img/logo.svg" alt="Logo" className="" />
                    </Link>
                </div>
                 <div className="hidden sm:flex gap-4">
                 <Link
                     to="https://docs.ivynet.dev/"
                     className="text-ivy-grey-100 hover:text-white font-ptmono py-2 rounded"
                     rel="noopener noreferrer"
                 >
                     /Docs
                 </Link>
                <Link
                    to="https://x.com/ivynet_dev"
                    className="text-ivy-grey-100 hover:text-white font-ptmono py-2 rounded"
                    target="_blank" rel="noopener noreferrer"
                >
                    /X
                </Link>
                    <Link
                        to="https://github.com/ivy-net"
                        className="text-ivy-grey-100 hover:text-white font-ptmono py-2 rounded"
                        target="_blank" rel="noopener noreferrer"
                    >
                        /GitHub
                    </Link>
                    <Link
                        to="https://github.com/ivy-net/iv1"
                        className="text-ivy-grey-100 hover:text-white font-ptmono py-2 rounded"
                        target="_blank" rel="noopener noreferrer"
                    >
                        /iv1
                    </Link>
                </div>
                <div className="sm:hidden">
                    <button
                        onClick={toggleMobileMenu}
                        className="focus:outline-none"
                    >
                        {isMobileMenuOpen ? (
                            // Close (X) icon
                            <svg
                                viewBox="0 0 24 24"
                                className="h-6 w-6 fill-current"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                />
                            </svg>
                        ) : (
                            // Hamburger icon
                            <svg
                                viewBox="0 0 24 24"
                                className="h-6 w-6 fill-current"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 6h18a1 1 0 010 2H3a1 1 0 110-2zm0 5h18a1 1 0 010 2H3a1 1 0 010-2zm0 5h18a1 1 0 010 2H3a1 1 0 010-2z"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            </nav>
            {isMobileMenuOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md z-50 flex flex-col items-center justify-center sm:hidden">
                    <button
                        onClick={toggleMobileMenu}
                        className="absolute top-5 right-5 focus:outline-none"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            className="h-8 w-8 fill-current text-white"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                            />
                        </svg>
                    </button>
                    <div className="text-center">
                    <Link
                         to="https://docs.ivynet.dev/"
                      className="text-white font-ptmono py-2 rounded block"
                        onClick={toggleMobileMenu}
                    >
                        Docs
                    </Link>
                        <Link
                             to="https://github.com/ivy-net"
                          className="text-white font-ptmono py-2 rounded block"
                            onClick={toggleMobileMenu}
                        >
                            GitHub
                        </Link>
                        <Link
                            to="https://github.com/ivy-net/iv1"
                            className="text-white font-ptmono py-2 rounded block"
                            onClick={toggleMobileMenu}
                            target="_blank" rel="noopener noreferrer"
                        >
                            iv1
                        </Link>
                        <Link
                            to="https://x.com/ivynet_dev"
                            className="text-white font-ptmono py-2 rounded block"
                            onClick={toggleMobileMenu}
                        >
                            X
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};

export default Nav;
